import React, { useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import { css } from "@emotion/react"
import { mediaQuery } from "../utils/mediaQuery"
import { GatsbyImage } from "gatsby-plugin-image"
import { breakpoints } from "../constants/breakpoints"

const tileContainerStyles = index => css`
  @media (max-width: ${breakpoints[1]}px) {
    ${index === 2 && "left: 50%;"}
  }

  position: relative;
  color: white;
  display: inherit;
`

const textWrapperStyles = css`
  bottom: 0;
  display: flex;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
`

const textContainerStyles = css`
  width: max-content;
  margin: auto;
`

const textStyles = css`
  background-color: rgba(0, 0, 0, 0.6);
  font-size: 1.5rem;
  margin: 0 auto 2px;
  padding: 5px;
  width: fit-content;
`

const titleStyles = css`
  font-size: 1.5rem;
`

const subTextStyles = css`
  font-size: 1rem;
`

const overlayStyles = showOverlay => css`
  @media (max-width: ${breakpoints[2]}px) {
    display: none;
  }
  position: absolute;
  opacity: ${showOverlay ? 1 : 0};
  z-index: 1;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  transition: opacity 500ms ease-in;
  display: flex;
  justify-content: center;
  align-items: center;
`

const overlayDescriptionStyles = css`
  padding: 10px;
  font-size: 0;

  ${mediaQuery[2]} {
    font-size: 1em;
  }
`

const Tile = ({ image, index, slug }) => {
  const [showOverlay, setShowOverlay] = useState(false)
  return (
    <Link
      css={tileContainerStyles(index)}
      to={`/${slug}`}
      onMouseOver={() => setShowOverlay(true)}
      onFocus={() => setShowOverlay(true)}
      onBlur={() => setShowOverlay(false)}
      onMouseLeave={() => setShowOverlay(false)}
    >
      <GatsbyImage
        image={image.childImageSharp.gatsbyImageData}
        alt={image.name}
      />
      <div css={textWrapperStyles}>
        <div css={textContainerStyles}>
          <h1 css={[textStyles, titleStyles]}>{image.name.toUpperCase()}</h1>
          {image.subText && (
            <p css={[textStyles, subTextStyles]}>{image.subText}</p>
          )}
        </div>
      </div>
      <div css={overlayStyles(showOverlay)}>
        <p css={overlayDescriptionStyles}>{image.description}</p>
      </div>
    </Link>
  )
}

const sectionStyles = css`
  color: white;
`

const gridStyles = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  padding: 10px;

  ${mediaQuery[1]} {
    grid-template-columns: 1fr 1fr 1fr;
    width: 70%;
    margin: 0 auto;
  }
`

export const HomeNav = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "home-nav" } }) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(
                tracedSVGOptions: { color: "#000000", background: "#ffffff" }
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
  `)

  const images = data.allFile.edges.reduce((acc, current) => {
    const coursesDescription =
      "We teach underwater photo and video to those divers who want to capture their dive memories forever, although if you are thinking of starting out in the industry, this may also be a great first step! Either way, find out more in the course tab."
    const imagingDescription =
      "Our photographers are out covering recreational sporting venues, and posting their photos for sale through our online sales platform! If you met one of them and want to see your pics or you're a photographer or venue looking to find out more, click here for the Tujo Imaging tab."
    const services =
      "We produce promotional content for all types of businesses and organizations. Whether it's video or photo, on land, in the air or beneath the waves, we can tailor our services to your needs. Find out more in the services tab."

    if (current.node.name === "courses") {
      current.node.description = coursesDescription
      return acc.concat(current.node)
    }

    if (current.node.name === "imaging") {
      current.node.description = imagingDescription
      current.node.subText = "view your photos"
      return acc.concat(current.node)
    }

    if (current.node.name === "services") {
      current.node.description = services
      return acc.concat(current.node)
    }

    return acc
  }, [])

  return (
    <section css={sectionStyles}>
      <div css={gridStyles}>
        {images &&
          images.map((image, index) => (
            <Tile image={image} key={index} index={index} slug={image.name} />
          ))}
      </div>
    </section>
  )
}
