import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { css } from "@emotion/react"
import { mediaQuery } from "../../utils/mediaQuery"
import { ArticleItem } from "./article-item"

const gridStyle = css`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
  padding: 10px;

  ${mediaQuery[1]} {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 10px;
  }

  ${mediaQuery[2]} {
    grid-template-columns: repeat(3, 1fr);
    padding: 0 10px;
  }
`

const ArticleGrid = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulArticle(sort: { fields: createdAt, order: DESC }) {
        nodes {
          slug
          contentful_id
          homePageTitle
          type
          createdAt(formatString: "DD MM YYYY")
          gif {
            file {
              url
            }
          }
          homePagePhoto {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
          }
        }
      }
    }
  `)

  const nodes = data.allContentfulArticle.nodes

  return (
    <section>
      <div css={gridStyle}>
        {nodes &&
          nodes.map(node => (
            <ArticleItem key={node.contentful_id} data={node} />
          ))}
      </div>
    </section>
  )
}

export default ArticleGrid
