import React, { useRef } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ArticleGrid from "../components/article/article-grid"

import { Video } from "../components/video"
import { css, useTheme } from "@emotion/react"
import { mediaQuery } from "../utils/mediaQuery"
import { useWindowSize } from "../hooks/useWindowSize"
import { HomeNav } from "../components/home-nav"

const containerStyle = (windowSize, theme) => css`
  background-color: rgba(0, 0, 0, 1);

  ${mediaQuery[1]} {
    min-height: calc(${windowSize.height}px - ${theme.heights.header});
    background-color: rgba(0, 0, 0, 0.8);
  }
`
const IndexPage = () => {
  const theme = useTheme()
  const windowSize = useWindowSize()
  const homeContentRef = useRef(null)

  return (
    <Layout isHome={true}>
      <Seo title="Home" />
      <Video homeContentRef={homeContentRef} />
      <div ref={homeContentRef} css={containerStyle(windowSize, theme)}>
        <HomeNav />
        <ArticleGrid />
      </div>
    </Layout>
  )
}

export default IndexPage
