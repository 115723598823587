import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"
import { Link } from "gatsby"

const linkStyles = css`
  display: inherit;

  :hover,
  :focus {
    img {
      visibility: visible;
      opacity: 1;
    }
  }
`

const containerStyles = css`
  position: relative;
  color: white;
  display: inherit;
`

const textContainerStyles = css`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 0;
  display: flex;
`

const textWrapperStyles = css`
  width: max-content;
  margin: auto;
`

const titleStyles = css`
  margin: 0;
  font-size: 1.5rem;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 5px;
  margin-bottom: 2px;
`

const typeStyles = css`
  margin: 0;
  font-size: 1em;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 5px;
  width: fit-content;
  margin: auto;
`

const gifStyles = css`
  position: absolute;
  height: 100%;
  width: auto;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s, visibility 0.5s;
  width: fill-available;
`

export const ArticleItem = ({ data }) => {
  return (
    <Link css={linkStyles} to={`/${data.slug}`}>
      <div css={containerStyles}>
        {data.gif && (
          <img alt={data.gif.title} css={gifStyles} src={data.gif.file.url} />
        )}
        <GatsbyImage
          image={data?.homePagePhoto?.gatsbyImageData}
          alt={data.homePageTitle}
        />
        <div css={textContainerStyles}>
          <div css={textWrapperStyles}>
            <h1 css={titleStyles}>{data.homePageTitle}</h1>
            <p css={typeStyles}>{data.type}</p>
          </div>
        </div>
      </div>
    </Link>
  )
}
