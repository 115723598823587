import React from "react"
import { useWindowSize } from "../hooks/useWindowSize"
import { css, keyframes } from "@emotion/react"
import { Viewport } from "../components/viewport"
import { breakpointConstants } from "../constants/breakpoints"
import videoSrc from "../videos/showreel.mp4"
import DownArrow from "../images/down-arrow.svg"
import { mediaQuery } from "../utils/mediaQuery"

const bounce = keyframes`
  from, 20%, 53%, 80%, to {
    transform: translate3d(0,0,0);
  }

  40%, 43% {
    transform: translate3d(0, -30px, 0);
  }

  70% {
    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0,-4px,0);
  }
`

const textContainerStyles = css`
  position: absolute;
  bottom: 20%;
  left: 5%;
  width: 50%;
`

const arrowStyles = css`
  height: 30px;
  width: 30px;
  position: absolute;
  bottom: 5%;
  left: calc(50% - 15px);
  animation: ${bounce} 1s infinite;
  animation-iteration-count: 3;
`

const videoStyles = windowSize => css`
  top: 0;
  position: sticky;
  z-index: -1;
  width: 100vw;
  object-fit: cover;
  display: block;

  ${mediaQuery[1]} {
    display: inherit;
    height: ${windowSize.height}px;
  }
`

const overlayStyles = css`
  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  height: 100%;
`

export const Video = ({ homeContentRef }) => {
  const windowSize = useWindowSize()

  return (
    <React.Fragment>
      <Viewport from={breakpointConstants.md}>
        <div css={overlayStyles} />
        <div css={textContainerStyles}>
          <h1>TUJO FILMS</h1>
          <p>
            We are a multi-faceted production company, born out of a love for
            content creation and a speciality in underwater camera work.
          </p>
        </div>
        <input
          type="image"
          src={DownArrow}
          alt="scroll down arrow"
          css={arrowStyles}
          onClick={() =>
            homeContentRef.current.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest",
            })
          }
        />
      </Viewport>
      <video css={videoStyles(windowSize)} autoPlay muted loop>
        <source src={videoSrc} type="video/mp4" />
      </video>
    </React.Fragment>
  )
}
